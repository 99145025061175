export const messages = {
	text: {
		empty: 'Поле обязательно к заполнению.',
		error: 'Поле должно содержать не менее 2 символов.',
	},
	textarea: {
		empty: 'Поле обязательно к заполнению.',
		error: 'Поле должно содержать не менее 2 и не более 150 символов.',
	},
	select: {
		empty: 'Выберите значение поля.',
	},
	complete: {
		empty: 'Поле обязательно к заполнению.',
		entry: 'Выберите значение поля.',
		error: 'Проверьте, правильно ли заполнено поле.',
	},
	file: {
		empty: 'Выберите файл.',
		size: 'Превышен допустимый размер.',
		accept: 'Неверный формат файла.',
	},
	code: {
		empty: 'Введите код из SMS.',
		error: 'Проверьте, правильно ли введен код.',
	},
	mask: {
		empty: 'Поле обязательно к заполнению.',
		error: 'Проверьте, правильно ли заполнено поле.',
	},
	email: {
		empty: 'Поле обязательно к заполнению.',
		error: 'Проверьте, правильно ли указан email.',
	},
	tel: {
		empty: 'Поле обязательно к заполнению.',
		error: 'Проверьте, правильно ли указан телефон.',
	},
	date: {
		empty: 'Поле обязательно к заполнению.',
		error: 'Проверьте, правильно ли указана дата.',
	},
	password: {
		empty: 'Поле обязательно к заполнению.',
		error: 'Поле должно содержать не менее 8 символов.',
	},
	conform: {
		empty: 'Поле обязательно к заполнению.',
		error: 'Значения полей не совподают.',
	},
	city: {
		empty: 'Поле обязательно к заполнению.',
		entry: 'Выберите название города.',
		error: 'Выберите название города.',
	},
	address: {
		empty: 'Поле обязательно к заполнению.',
		entry: 'Введите корректный адрес.',
		error: 'Введите полный адрес, c указанием номера дома.',
	},
};
