import { rules } from '../../composition/rules';
import { messages } from '../../composition/messages';

export function useGetValid(
	$element: HTMLInputElement,
	complite: boolean,
	selected: Option | undefined,
	options: OptionsVE,
): ResultVE {
	const result: ResultVE = {
		valid: false,
		error: false,
		message: '',
	};

	if (!options.noEmpty && $element.value.length === 0) {
		const valid: boolean = rules['empty'].validate($element);
		result.valid = valid;
		result.error = !valid;
		result.message = messages[options.message]['empty'];
	}

	if (complite) {
		result.valid = true;
		result.error = false;
		return result;
	}

	if ($element.value.length > 0 && !selected) {
		result.valid = false;
		result.error = true;
		result.message = messages[options.message]['entry'];
	}

	if ($element.value.length > 0 && selected && !selected.complete) {
		result.valid = false;
		result.error = true;
		result.message = messages[options.message]['error'];
	}

	if ($element.value.length > 0 && selected && selected.complete) {
		result.valid = true;
		result.error = false;
		result.message = messages[options.message]['error'];
	}

	return result;
}
