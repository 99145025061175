import { messages } from '../../composition/messages';

export function useGetValid(
	$element: HTMLInputElement,
	length: number,
	options: OptionsVE,
): ResultVE {
	const result: ResultVE = {
		valid: false,
		error: false,
		message: '',
	};

	if (!options.noEmpty && $element.value.length === 0) {
		result.valid = false;
		result.error = true;
		result.message = messages[options.message]['empty'];
	}

	if ($element.value.length) {
		if ($element.value.length === length) {
			result.valid = true;
			result.error = false;
		} else {
			result.valid = false;
			result.error = true;
			result.message = messages[options.message]['error'];
		}
	}

	return result;
}
