import { rules } from '../../composition/rules';
import { messages } from '../../composition/messages';

export function useGetValid(
	$element: HTMLInputElement | HTMLTextAreaElement,
	options: OptionsVE,
): ResultVE {
	const result: ResultVE = {
		valid: false,
		error: false,
		message: '',
	};

	if (!options.noEmpty && $element.value.length === 0) {
		const valid: boolean = rules['empty'].validate($element);
		result.valid = valid;
		result.error = !valid;
		result.message = messages[options.message]['empty'];
	}

	if ($element.value.length > 0) {
		const valid: boolean = rules[options.rule].validate($element);
		result.valid = valid;
		result.error = !valid;
		result.message = messages[options.message]['error'];
	}

	return result;
}
