import.meta.globEager('@/assets/images/**/*.{jpg,jpeg,png}', {
	query: { format: 'webp', quality: 80 },
});

import { vars } from './config/variables';

import {
	disableScroll,
	enableScroll,
	getDeviceType,
	classInstance,
} from '@/shared/helpers/helpers';

import { Form } from '@/shared/form/form';

window.app = {};
window.vars = vars;

window.app.classInstance = classInstance;
window.app.disableScroll = disableScroll;
window.app.enableScroll = enableScroll;
window.app.getDeviceType = getDeviceType;

window.app.Form = Form;

window.addEventListener('DOMContentLoaded', () => {
	document.querySelectorAll('.js-form').forEach((element) => new Form(element));
});
