const vars = {
	breakpoint: {
		mobile: 425,
		tablet: 768,
		laptop: 1024,
		desktopSmall: 1280,
		desktopMiddle: 1440,
		desktopLarge: 1680,
	},
};

export { vars };
